import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    deleteDoc,
    query,
    orderBy,
    limit,
    startAfter,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { AuthContext } from "./AuthContext";
import Modal from "react-modal";

const AllVideos = () => {
    const [videos, setVideos] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [isVideoDeleteModalOpen, setIsVideoDeleteModalOpen] = useState(false);
    const [videoToDelete, setVideoToDelete] = useState(null);
    const [featuredVideos, setFeaturedVideos] = useState([]);
    const { isSuperadmin } = useContext(AuthContext);

    const fetchVideos = useCallback(async (nextPage = false) => {
        if (!isSuperadmin) return;

        setLoading(true);
        try {
            const db = getFirestore();
            let videosQuery = query(
                collection(db, "videos"),
                orderBy("title_lowercase"),
                limit(50)
            );

            if (nextPage && lastVisible) {
                videosQuery = query(
                    collection(db, "videos"),
                    orderBy("title_lowercase"),
                    startAfter(lastVisible),
                    limit(50)
                );
            }

            const videosSnapshot = await getDocs(videosQuery);
            const videosList = videosSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setVideos((prevVideos) =>
                nextPage ? [...prevVideos, ...videosList] : videosList
            );

            if (videosSnapshot.docs.length < 50) {
                setLastVisible(null);
            } else {
                setLastVisible(
                    videosSnapshot.docs[videosSnapshot.docs.length - 1]
                );
            }

            // Fetch the current featured videos
            const featuredVideosDocRef = doc(db, "featured_videos", "videos");
            const featuredVideosDoc = await getDoc(featuredVideosDocRef);
            if (featuredVideosDoc.exists()) {
                setFeaturedVideos(featuredVideosDoc.data().video_id || []);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
        } finally {
            setLoading(false);
        }
    }, [isSuperadmin, lastVisible]);

    useEffect(() => {
        if (isSuperadmin) {
            fetchVideos();
        }
    }, [isSuperadmin, fetchVideos]);

    const handleSearch = () => {
        fetchVideos(false);
    };

    const openVideoDeleteModal = (video) => {
        setVideoToDelete(video);
        setIsVideoDeleteModalOpen(true);
    };

    const closeVideoDeleteModal = () => {
        setVideoToDelete(null);
        setIsVideoDeleteModalOpen(false);
    };

    const handleDeleteVideo = async () => {
        if (!videoToDelete) return;

        const db = getFirestore();
        const videoDocRef = doc(db, "videos", videoToDelete.id);

        try {
            await deleteDoc(videoDocRef);
            fetchVideos(); // Refresh videos after deletion
            closeVideoDeleteModal();
            alert("Vidéo supprimée avec succès !");
        } catch (error) {
            console.error("Error deleting video: ", error);
            alert("An error occurred while deleting the video. Please try again.");
        }
    };

    const toggleFeatured = async (videoId) => {
        const db = getFirestore();
        const featuredVideosDocRef = doc(db, "featured_videos", "videos");

        try {
            let updatedFeaturedVideos = [...featuredVideos];
            if (featuredVideos.includes(videoId)) {
                // Remove video from featured
                updatedFeaturedVideos = updatedFeaturedVideos.filter(id => id !== videoId);
            } else {
                // Add video to featured
                updatedFeaturedVideos.unshift(videoId);
                if (updatedFeaturedVideos.length > 10) {
                    updatedFeaturedVideos.pop(); // Keep only the first 10 videos
                }
            }

            await updateDoc(featuredVideosDocRef, {
                video_id: updatedFeaturedVideos,
            });

            setFeaturedVideos(updatedFeaturedVideos);
            alert(
                featuredVideos.includes(videoId)
                    ? "Video removed from featured videos."
                    : "Video added to featured videos."
            );
        } catch (error) {
            console.error("Error updating featured videos:", error);
            alert("An error occurred while updating the featured video. Please try again.");
        }
    };

    const loadMoreVideos = () => {
        fetchVideos(true);
    };

    if (!isSuperadmin) {
        return <p>Access denied. This page is only available to superadmins.</p>;
    }

    return (
        <div className="container">
            <div className="search-container">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Rechercher une vidéo..."
                    className="search-input"
                />
                <button onClick={handleSearch} className="search-button">
                    Rechercher
                </button>
            </div>

            <div className="video-list">
                {videos.map((video) => (
                    <div key={video.id} className="video-item">
                        <a
                            href={video.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={`https://img.youtube.com/vi/${video.video_url.split(
                                    "v="
                                )[1]}/0.jpg`}
                                alt={video.title}
                            />
                        </a>
                        <h3>{video.title}</h3>
                        <button
                            onClick={() => toggleFeatured(video.id)}
                            className={
                                featuredVideos.includes(video.id)
                                    ? "remove-feature-button"
                                    : "search-button"
                            }
                        >
                            {featuredVideos.includes(video.id)
                                ? "Retirer"
                                : "Mettre en avant"}
                        </button>
                        <button
                            onClick={() => openVideoDeleteModal(video)}
                            className="delete-video-button"
                        >
                            ×
                        </button>
                    </div>
                ))}
            </div>

            <div className="pagination">
                {loading ? (
                    <p>Chargement...</p>
                ) : (
                    lastVisible && (
                        <button
                            onClick={loadMoreVideos}
                            className="load-more-button"
                        >
                            Charger plus
                        </button>
                    )
                )}
            </div>

            <Modal
                isOpen={isVideoDeleteModalOpen}
                ariaHideApp={false}
                onRequestClose={closeVideoDeleteModal}
                contentLabel="Delete Video"
                style={modalStyles}
            >
                <h2>T'es sûr que tu veux dégager cette vidéo ?</h2>
                <button onClick={handleDeleteVideo} className="save-button">
                    Vazy
                </button>
                <button
                    onClick={closeVideoDeleteModal}
                    className="cancel-button"
                >
                    Non
                </button>
            </Modal>
        </div>
    );
};

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "20px",
        width: "300px",
        borderRadius: "10px",
    },
};

export default AllVideos;
