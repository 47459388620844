import React, { useState, useEffect, useContext, useCallback } from "react";
import { getFirestore, collection, getDocs, addDoc, doc, updateDoc, arrayUnion, getDoc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "./AuthContext";
import { useNavigate, Link } from "react-router-dom";
import './css/styles.css';

const generateUniqueFilename = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    return `${timestamp}_${randomString}.jpg`;
};

const resizeAndCropImage = (file, targetWidth, targetHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {
            const sourceWidth = img.width;
            const sourceHeight = img.height;

            const targetRatio = targetWidth / targetHeight;
            const sourceRatio = sourceWidth / sourceHeight;

            let cropWidth, cropHeight, offsetX, offsetY;

            if (sourceRatio > targetRatio) {
                cropHeight = sourceHeight;
                cropWidth = cropHeight * targetRatio;
                offsetX = (sourceWidth - cropWidth) / 2;
                offsetY = 0;
            } else {
                cropWidth = sourceWidth;
                cropHeight = cropWidth / targetRatio;
                offsetX = 0;
                offsetY = (sourceHeight - cropHeight) / 2;
            }

            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.drawImage(img, offsetX, offsetY, cropWidth, cropHeight, 0, 0, targetWidth, targetHeight);

            canvas.toBlob(blob => {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
            }, file.type);
        };

        img.onerror = (err) => {
            reject(err);
        };

        img.src = URL.createObjectURL(file);
    });
};

const AddVideo = () => {
    const [categories, setCategories] = useState([]);
    const [newVideoTitle, setNewVideoTitle] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [horImageFile, setHorImageFile] = useState(null);
    const [verImageFile, setVerImageFile] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isCreatorActive, setIsCreatorActive] = useState(false);

    const checkCreatorStatus = useCallback(async () => {
        try {
            const db = getFirestore();
            const creatorDocRef = doc(db, "creators", currentUser.uid);
            const creatorDoc = await getDoc(creatorDocRef);

            if (creatorDoc.exists()) {
                const creatorData = creatorDoc.data();
                if (creatorData.is_active && creatorData.is_live) {
                    setIsCreatorActive(true);
                    fetchCategories();
                } else {
                    setIsCreatorActive(false);
                }
            } else {
                setIsCreatorActive(false);
            }
        } catch (error) {
            console.error("Error checking creator status:", error);
            setIsCreatorActive(false);
        } finally {
            setLoading(false);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            checkCreatorStatus();
        } else {
            navigate("/login");
        }
    }, [currentUser, navigate, checkCreatorStatus]);

    const fetchCategories = async () => {
        try {
            const db = getFirestore();
            const categoriesCollection = collection(db, "video_categories");
            const categoriesSnapshot = await getDocs(categoriesCollection);
            const categoriesList = categoriesSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
            }));
            setCategories(categoriesList);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleImageUpload = async (file, targetWidth, targetHeight, fieldName) => {
        const resizedImage = await resizeAndCropImage(file, targetWidth, targetHeight);
        const storage = getStorage();
        const uniqueFilename = generateUniqueFilename();
        const imageRef = ref(storage, `videos/${currentUser.uid}/${uniqueFilename}`);
        const snapshot = await uploadBytes(imageRef, resizedImage);
        const imageUrl = await getDownloadURL(snapshot.ref);

        if (fieldName === "hor_img_url") {
            setHorImageFile(imageUrl);
        } else if (fieldName === "ver_img_url") {
            setVerImageFile(imageUrl);
        }
    };

    const handleAddVideo = async () => {
        if (
            newVideoTitle.trim() === "" ||
            videoUrl.trim() === "" ||
            !horImageFile ||
            !verImageFile ||
            selectedCategories.length === 0
        ) {
            alert("Please fill in all fields, select at least one category, and upload both images.");
            return;
        }

        try {
            const db = getFirestore();
            const videosCollection = collection(db, "videos");

            const newVideo = {
                title: newVideoTitle,
                title_lowercase: newVideoTitle.toLowerCase(),
                hor_img_url: horImageFile,
                ver_img_url: verImageFile,
                video_url: videoUrl,
                categories: selectedCategories,
                creatorId: currentUser.uid,
            };

            const videoDocRef = await addDoc(videosCollection, newVideo);

            const creatorDocRef = doc(db, "creators", currentUser.uid);
            await updateDoc(creatorDocRef, {
                videos: arrayUnion(videoDocRef.id),
            });

            const latestVideosDocRef = doc(db, "latest_videos", "videos");
            const latestVideosDoc = await getDoc(latestVideosDocRef);

            if (latestVideosDoc.exists()) {
                const latestVideos = latestVideosDoc.data().video_id || [];
                if (latestVideos.length >= 11) {
                    latestVideos.shift();
                }
                latestVideos.push(videoDocRef.id);

                await updateDoc(latestVideosDocRef, {
                    video_id: latestVideos,
                });
            } else {
                await setDoc(latestVideosDocRef, {
                    video_id: [videoDocRef.id],
                });
            }

            resetForm();
            alert("Video added successfully!");
        } catch (error) {
            console.error("Error adding video:", error);
            alert("An error occurred while adding the video. Please try again.");
        }
    };

    const resetForm = () => {
        setNewVideoTitle("");
        setVideoUrl("");
        setHorImageFile(null);
        setVerImageFile(null);
        setSelectedCategories([]);
    };

    const handleCategoryChange = (e) => {
        const value = e.target.value;
        setSelectedCategories(
            selectedCategories.includes(value)
                ? selectedCategories.filter((category) => category !== value)
                : [...selectedCategories, value]
        );
    };

    return (
        <div className="add-video-container">
            {loading ? (
                <p>Loading...</p>
            ) : isCreatorActive ? (
                <>
                    <h2>Ajoute une nouvelle vidéo</h2>
                    <input
                        type="text"
                        value={newVideoTitle}
                        onChange={(e) => setNewVideoTitle(e.target.value)}
                        placeholder="Titre"
                        className="video-input"
                    />
                    <input
                        type="text"
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                        placeholder="Lien YouTube"
                        className="video-input"
                    />
                    <div className="categories-checkboxes">
                        {categories.map((category) => (
                            <div key={category.id} className="category-checkbox">
                                <input
                                    type="checkbox"
                                    id={category.id}
                                    value={category.id}
                                    checked={selectedCategories.includes(category.id)}
                                    onChange={handleCategoryChange}
                                />
                                <label htmlFor={category.id}>{category.name}</label>
                            </div>
                        ))}
                    </div>
                    <div className="form-group">
                        <label>Image Horizontale (900x600) (requis)</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e.target.files[0], 900, 600, "hor_img_url")}
                        />
                        {horImageFile && <img src={horImageFile} alt="Horizontal" className="preview-image" style={{ width: '200px' }} />}
                    </div>
                    <div className="form-group">
                        <label>Image Verticale (600x900) (requis)</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e.target.files[0], 600, 900, "ver_img_url")}
                        />
                        {verImageFile && <img src={verImageFile} alt="Vertical" className="preview-image" style={{ width: '200px' }} />}
                    </div>
                    <button onClick={handleAddVideo} className="add-video-button">AJOUTER</button>
                </>
            ) : (
                <p>
                    Vous devez publier votre page avant de pouvoir ajouter une vidéo.{" "}
                    <Link to="/pagesettings" className="settings-link">
                        Cliquez ici pour publier votre page.
                    </Link>
                </p>
            )}
        </div>
    );
};

export default AddVideo;
